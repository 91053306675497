@mixin buttonStyle($margin, $color, $bg-color, $border) {
  color: $color;
  background: $bg-color;
  border: 1px solid $border;
  font-weight: var(--font-semibold);
  font-size: var(--XSmall);
  padding: 8px 26px;
}
@media only screen and (min-width: 768px) {
  .modelEquityPortfolioContainer {
    width: 95%;
    padding: 20px 28px 48px 28px;
    .showButton {
      margin-bottom: 20px;
      background: var(--blue10);
      color: var(--blue7);
      font-size: var(--XXSmall);
      font-weight: var(--font-medium);
      padding: 14px 20px 14px 20px;
    }
    .table {
      width: 100%;
      padding-bottom: 30px;
      .tableHeaderCustomClass {
        background: var(--blue11);
        color: var(--white1);
        font-weight: var(--font-medium);
        height: 50px;
      }
      .customClassForTh {
        text-align: start;
        padding: 22px 15px;
      }
      .customClassForTd {
        padding: 22px 15px;
        text-align: start;
      }
      .tableBodyRow {
        background: var(--blue8);
        // height: 66px;
        font-size: var(--XXSmall);
        font-weight: var(--font-medium);
        color: var(--black2);
        &:nth-child(even) {
          background: var(--blue9);
        }
      }

      .tableCash {
        .tableCashTd {
          padding: 22px 15px;
          text-align: start;
        }
        color: var(--black1);
        font-size: var(--XXSmall);
        font-weight: var(--font-semibold);
        background: var(--blue8);
      }
      .tableBodyFooter {
        .tableCashTd {
          padding: 22px 16px;
          text-align: start;
        }
        color: var(--black1);
        font-size: var(--XXSmall);
        font-weight: var(--font-semibold);
        background: var(--blue9);
      }
    }
    .tableFooter {
      width: 100%;
      td {
        padding: 22px 15px;
        text-align: start;
      }
      .tableBodyFooter {
        color: var(--black1);
        font-size: var(--XXSmall);
        font-weight: var(--font-semibold);
        background: var(--blue9);
      }
      .customClassForTd {
        margin-left: 40px;
      }
    }
    .filterContainer {
      margin-bottom: 30px;
    }
    .search {
      background: var(--grey17);
      box-shadow: none;
      width: 20%;
      margin-bottom: 20px;
    }
    .input {
      width: 75%;
      background: var(--grey17);
    }
    .jseTaxFreeAccountTFSA {
      .jseTaxFreeAccountTFSA {
        color: var(--black1);
        font-size: var(--medium);
        font-weight: var(--font-medium);
      }
      .jseTaxFreeAccountTFSATable {
        width: 100%;
        .tableHeaderCustomClass {
          background: var(--blue11);
          color: var(--white1);
          font-weight: var(--font-medium);
          height: 50px;
        }
        .customClassForTh {
          text-align: start;
          padding: 22px 10px;
        }
        .tableBodyRow {
          background: var(--blue8);
          // height: 66px;
          font-size: var(--XXSmall);
          font-weight: var(--font-medium);
          color: var(--black2);
          &:nth-child(even) {
            background: var(--blue9);
          }
        }
        .customClassForTd {
          padding: 22px 15px;
          text-align: start;
        }
      }
    }
    .JSETaxFreeAccountTable {
      width: 100%;
      margin-top: 12px;
      .tableHeaderCustomClass {
        background: var(--blue11);
        color: var(--white1);
        font-weight: var(--font-medium);
        font-size: var(--XSmall);
        height: 50px;
      }
      .customClassForTh {
        text-align: center;
        padding: 22px 15px;
      }
      .customClassForTd {
        padding: 23px 0px;
        text-align: center;
      }

      th,
      td {
        padding: 0px 33px 0px 33px;
      }

      .tableHead {
        background: var(--blue11);
        height: 50px;
        color: var(--white1);
        font-size: var(--XSmall);
        font-weight: var(--font-medium);
        text-align: center;
        input {
          height: 18px;
          width: 18px;
        }
      }
      .tableBodyRow {
        background: var(--blue8);
        height: 66px;
        // font-size: var(--XXSmall);
        // font-weight: var(--font-medium);
        // color: var(--black1);

        background: var(--blue8);
        font-size: var(--XXSmall);
        font-weight: var(--font-medium);
        color: var(--black2);
        text-align: center;
        &:nth-child(even) {
          background: var(--blue9);
        }
      }
    }
    .paginationContainer {
      display: flex;
      justify-content: space-between;
      padding: 30px 0px 0px 0px;
    }
  }
}
