@mixin fees {
  margin-left: 42px;
  font-weight: var(--font-bold);
  color: var(--blue7);
  font-size: var(--XSmall);
}

@media only screen and (min-width: 768px) {
  .yearReportContainer {
    width: 80%;
    margin: 4% auto 0px auto;
    .yearReportCross {
      background-color: var(--white1);
      text-align: center;
      padding: 25px 0px 20px 0px;
      background-color: var(--blue1);
      color: var(--white1);
      font-weight: var(--font-semibold);
      font-size: var(--medium);

      .crossIcon {
        float: right;
        margin-right: 36px;
      }
    }

    .popupContainer {
      background-color: var(--white1);

      .tableData {
        display: flex;
        justify-content: space-between;
        padding: 22px 94px 10px 40px;

        .yearTitle {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 17%;

          .title {
            background-color: var(--grey18);
            padding: 9px 10px 9px 10px;
          }
        }

        .mainData {
          display: flex;
          width: 33%;
          justify-content: space-between;
          .lastMonth {
            display: flex;
            align-items: center;
            background-color: var(--blue2);
            box-shadow: 1px 1px 8px -1px rgba(0, 0, 0, 0.1);
            justify-content: space-between;
            width: 80%;
            padding: 13px 23px;
          }
          .dropdownListContent {
            color: var(--black1);
          }

          .search {
            background-color: var(--blue10);
            color: var(--blue1);
            padding: 13px 32px 14px 32px;
            font-weight: var(--font-semibold);
            font-size: var(--XSmall);
          }
        }
      }

      .month {
        margin-left: 43px;
        color: var(--black1);
        font-weight: var(--font-semibold);
        font-size: var(--medium);
        margin-top: 21px;
      }
      .feeSummary {
        @include fees();
        margin-top: 9px;
      }
      .mapDetails {
        display: flex;
        align-items: center;
        justify-content: space-between;

        margin: 30px 0px 20px 53px;
        .mainContainer {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 15%;
          font-weight: var(--font-bold);
          margin-right: 64px;
        }
        .title {
          font-weight: var(--font-medium);
          font-size: var(--XXSmall);
        }
        .country {
          color: var(--blue1);
          font-weight: var(--font-medium);
          font-size: var(--XXSmall);
        }
        .price {
          color: var(--blue1);
          font-weight: var(--font-medium);
          font-size: var(--XXSmall);
        }
      }
      .feesPeriod {
        @include fees();
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 4px;
        margin-left: 49px;
        .container {
          display: flex;
          width: 15%;
          justify-content: space-between;
          margin-right: 64px;
        }
      }
    }
    .monthTitle {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 55%;
      margin-left: 40px;

      .title {
        background-color: var(--grey18);
        padding: 9px 10px 9px 10px;
      }
    }
    .pdfContainer {
      display: flex;
      align-items: center;

      padding: 14px 48px 10px 0px;
      background-color: var(--blue1);

      .button {
        display: flex;
        justify-content: right;
        width: 100%;
        .pdfButton {
          background-color: var(--blue10);
          padding: 14px 43px 13px 43px;
          color: var(--blue1);
          font-weight: var(--font-semibold);
          font-size: var(--XSmall);
        }
      }
    }
  }
}
