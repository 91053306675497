@media only screen and (min-width: 768px) {
    .slidecontainer{
        width: 98px;
        // margin: auto;
        .slider{
            -webkit-appearance: none;
            width: 100%;
            height: 10px;
            border-radius: 12px;
            background: var(--blue1);
            -webkit-transition: .2s;
            transition: opacity .2s;
            &::-webkit-slider-thumb {
                -webkit-appearance: none;
                appearance: none;
                width: 17px;
                height: 17px;
                border-radius: 50%;
                background: var(--white1);
                border: 1px solid var(--blue7);
                cursor: pointer;
              }
              
              &::-moz-range-thumb {
                width: 17px;
                height: 17px;
                border-radius: 50%;
                background: var(--white1);
                border: 1px solid var(--blue7);
                cursor: pointer;
              }
        }
    }
}