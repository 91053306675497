.datePickerContainer > .react-datepicker-popper[data-placement^="bottom"],
.react-datepicker__triangle::before,
.react-datepicker__triangle::after,
.react-datepicker__header {
  border: none !important;
  border-color: none !important;
  background-color: unset !important;
}

.react-datepicker {
  color: var(--grey1) !important;
  border: none !important;
  display: inline-block;
  position: relative;
  box-shadow: 0px 4px 30px 10px rgba(0, 0, 0, 0.1);
  font-weight: var(--font-medium);
  font-size: var(--XXXSmall);
}
.react-datepicker__month-text--keyboard-selected {
  background-color: unset !important;
  color: var(--blue1) !important;
}
.react-datepicker__month .react-datepicker__month-text,
.react-datepicker__month .react-datepicker__quarter-text {
  display: inline-block;
  width: 4rem;
  margin: 8px;
}
.react-datepicker__navigation {
  padding: 25px;
}
.react-datepicker-year-header {
  margin-top: 5px;
  font-weight: var(--font-semibold);
  font-size: var(--XSmall);
}
.react-datepicker__input-container {
  position:unset !important;
  display: inline-block;
  width: 150px !important;
}
