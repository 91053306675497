@media only screen and (min-width: 768px) {
  .dropdownContent {
    position: absolute;
    background: var(--blue2);
    min-width: 160px;
    padding: 12px 16px 12px 46px;
    box-shadow: 1px 1px 8px -1px rgba(0, 0, 0, 0.1);
    z-index: 1;
    // padding-left: 46px;
    color: var(--grey1);
    .dropdownItem {
      cursor: pointer;
    }
  }
}
