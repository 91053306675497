body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --XXXXXXSmall: 9px;
  --XXXXXSmall: 10px;
  --XXXXSmall: 13px;
  --XXXSmall: 12px;
  --XXSmall: 14px;
  --XSmall: 16px;
  --small: 18px;
  --medium: 20px;
  --large: 22px;
  --XLarge: 24px;
  --XXLarge: 28px;
  --XXXLarge: 25px;
  --XXXXLarge: 30px;
  --XXXXXLarge: 50px;
  --MSmall: 15px;
  --font-thin: 100;
  --font-extralight: 200;
  --font-light: 300;
  --font-normal: 400;
  --font-medium: 500;
  --font-semibold: 600;
  --font-bold: 700;
  --font-extrabold: 800;
  --font-black: 900;
  --white1: #ffffff;
  --white2: #f8f8f8;
  --white3: #83B3D4;
  --black1: #000000;
  --black2: #0f0f0f;
  --black3: #011a2c;
  --black4: #b9b7bc;
  --black5: #585858;
  --black6: #011a2ccc;
  --blue1: #0b6aab;
  --blue2: #fafbff;
  --blue3: #0968a8;
  --blue4: #075f9b1a;
  --blue5: #41719c;
  --blue6: #e7eff5;
  --blue7: #0868aa;
  --blue8: #d2ddef;
  --blue9: #eaeff7;
  --blue10: #dcf1ff;
  --blue11: #5b9bd5;
  --blue12: #eaf6ff;
  --blue13: #ccd2e3;
  --blue14: #72c5ff;
  --blue15: #43a7ec;
  --blue16: #208ad2;
  --blue17: #064f81;
  --blue18: #aed9f7;
  --grey1: #969ba0;
  --grey2: #f4f8fa;
  --grey3: #a3a7ac;
  --grey4: #f9f9f9;
  --grey5: #787878;
  --grey6: #dbdbdb;
  --grey7: #989898;
  --grey8: #ababab;
  --grey9: #aaaaaa;
  --grey10: #3e4954;
  --grey11: #d3d6e4;
  --grey12: #bfbfbf;
  --grey13: #a5a5a5;
  --grey14: #f5f5f5;
  --grey15: #f2f2f2;
  --grey16: #eeeeee;
  --grey17: #f1f1f1;
  --grey18: #efefef;
  --grey19: #0f0f0fb3;
  --grey20: #f8f8f8;
  --grey21: #c4c4c4;
  --grey22: #e5e5e5;
  --green1: #2bc155;
  --red1: #ff2e2e;
  --red2: #f42e2e;
  --orange1: #efaa2f;
  --orange2: #ffddac;
  --orange3: #ffab2d;
}

* {
  font-family: "Poppins", sans-serif;
}
