@media only screen and (min-width: 768px) {
  .textFieldContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
    .label {
      color: var(--black5);
      margin-bottom: 5px;
      font-weight: var(--font-normal);
      font-size: var(--XXSmall);
    }
    .type {
      width: 162px;
      padding: 6px 12px 5px 0px;
      color: var(--black2);
      border: 1px solid var(--black4);
    }
    .inputType {
      //padding: 5px 0px;
      width: 90%;
      color: var(--black2);
      border: 1px solid var(--black4);
    }
    .icon {
      padding: 12px;
    }
  }
}
