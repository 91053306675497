@mixin buttonStyle($margin, $color, $bg-color, $border) {
  color: $color;
  background: $bg-color;
  border: 1px solid $border;
  font-weight: var(--font-semibold);
  font-size: var(--XSmall);
  padding: 7px 10px;
}
@media only screen and (min-width: 768px) {
  .clientsContainer {
    width: 95%;
    margin: auto;
    padding: 30px 0px 51px 0px;

    .allClients {
      background: var(--white1);
      margin-bottom: 36px;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
      .divider {
        height: 51px;
        border: 1px solid var(--blue12);
      }
      .allClientsText {
        color: var(--black2);
        font-size: var(--XXSmall);
        font-weight: var(--font-semibold);
      }
      .initialLetter {
        font-size: var(--XXSmall);
        color: var(--grey1);
        font-family: var(--font-medium);
      }
    }
    .searchWrapper {
      display: flex;
      justify-content: space-between;
      margin-bottom: 37px;
      .search {
        background: var(--grey17);
        box-shadow: none;
        width: 30%;
      }
      .input {
        width: 75%;
        background: var(--grey17);
        margin: 0px;
      }
      .addNewClient {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 40%;
        .investmentValueContainer{
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 220px;
          .investmentValue {
            flex: 1;
            font-size: var(--XXSmall);
            font-weight: var(--font-semibold);
          }
        }
        .dropdownContainer {
          border: none;
          background: var(--white1);
          border-radius: 2px;
          padding: 16px 0px;
          .dropdownContainerItems {
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            width: 170px;
            .dropdownContent {
              font-size: var(--XXSmall);
              color: var(--grey10);
            }
          }
          .dropdownListContent {
            margin-top: 10px;
            // border: 1px solid var(--grey1);
            border-top: none;
            width: 170px;
            height: 150px;
            padding: 0px;
            text-align: center;
            overflow-y: scroll;
          }
          ::-webkit-scrollbar {
            width: 0px;
          }
        }
        .newClientButton {
          display: flex;
          align-items: center;
          background: var(--blue10);
          color: var(--blue7);
          font-size: var(--XXSmall);
          font-weight: var(--font-medium);
          padding: 10px 16px;
        }
      }
    }
    .table {
      width: 100%;
      th,
      td {
        padding: 0px 10px;
      }
      .tableHead {
        background: var(--blue11);
        height: 66px;
        color: var(--white1);
        font-size: var(--XSmall);
        font-weight: var(----font-medium);
        text-align: left;

        input {
          height: 18px;
          width: 18px;
        }
      }
      .tableBodyRow {
        background: var(--blue8);
        cursor: pointer;
        height: 66px;
        font-size: var(--XXSmall);
        font-weight: var(--font-medium);
        color: var(--black2);
        white-space: nowrap;
        .checkbox {
          input {
            width: 18px;
            height: 18px;
            border: 2px solid var(--black2);
            border-radius: 3px;
          }
        }

        .gotoButton {
          @include buttonStyle(0px, var(--black1), var(--blue8), var(--black1));
          &:hover {
            @include buttonStyle(
              0px,
              var(--white1),
              var(--blue1),
              var(--blue1)
            );
          }
        }
        &:nth-child(even) {
          background: var(--blue9);
          .gotoButton {
            @include buttonStyle(
              0px,
              var(--black1),
              var(--blue9),
              var(--black1)
            );
            &:hover {
              @include buttonStyle(
                0px,
                var(--white1),
                var(--blue1),
                var(--blue1)
              );
            }
          }
        }
      }
    }
    .paginationContainer {
      display: flex;
      justify-content: space-between;
      padding: 30px 0px 0px 0px;
    }
  }
}
