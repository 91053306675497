@media only screen and (min-width: 768px) {
  .balanceContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 90%;
    padding: 10px 20px;
    cursor: pointer;
    // background: repeating-linear-gradient(#74ABDD, #74ABDD 49.9%, #000000 50.1%, #498DCB 100%);
    //background: linear-gradient(to left, #333 50%, green 50%);
    .title {
      font-size: var(--XXXSmall);
      font-weight: var(--font-medium);
      color: var(--black2);
      display: inline-block;
      min-width: 125px;
      text-align: center;
    }
    // .gotoBtn{
    //     font-size: var(--XXXSmall);
    //     font-weight: var(--font-semibold);
    //     color: var(--blue1);
    //     cursor: pointer;
    // }
  }
}
