@media only screen and (min-width: 768px) {
  .mainContainer {
    width: 100%;
    min-height: 100vh;

    .tableContainer {
      width: 95%;
      margin: auto;
      padding: 30px 0px;
      .table {
        width: 100%;
        th,
        td {
          text-align: center;
          // padding: 0px 8px;
        }
        td:nth-child(1) {
          text-align: left;
          padding: 0px 5px;
        }
        th:nth-child(1) {
          text-align: left;
          padding: 0px 5px;
        }
        .tableHead {
          background: var(--blue11);
          height: 50px;
          color: var(--white1);
          font-size: var(--XXSmall);
          font-weight: var(--font-medium);
          text-align: left;
        }
        .tableBodyRow {
          background: var(--blue8);
          height: 66px;
          font-size: var(--XXSmall);
          font-weight: var(--font-medium);
          color: var(--black2);
          cursor: pointer;
          &:nth-child(even) {
            background: var(--blue9);
          }
        }
      }
    }
  }
}
