@media only screen and (min-width: 768px) {
  .clientTransactionContainer {
    .table {
      width: 100%;
      border-collapse: collapse;

      margin: 14px 0px 40px 0px;
      // box-shadow: 0px 3px 6px rgba(62, 73, 84, 0.04);
      text-align: center;
      .clientTableHeader {
        background: var(--blue11);
        color: var(--white1);
        font-size: var(--XXXSmall);
        font-weight: var(--font-medium);
        .headerData {
          padding: 14px 0px;
        }
      }

      .clientTableRow {
        font-size: var(--XXXXXXSmall);
        font-weight: var(--font-medium);
        background: var(--white1);
        color: var(--black1);
        border-bottom: 0.5px solid var(--blue7);
        border-top: 0.5px solid var(--blue7);

        .rowData {
          padding: 5px 11px 5px 5px;
        }
        .editBtn {
          padding: 0px;
        }
      }
    }
  }
}
