@mixin titleStyle($font) {
  background: var(--grey11);
  font-weight: var(--font-bold);
  font-size: $font;
  text-align: center;
}
@media only screen and (min-width: 768px) {
  .landingPage {
    position: relative;
    // background: var(--black6);
    // opacity: 80%;
    width: 100%;
    min-height: 755px;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    // padding: 40px 0px 40px 0px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;
    .modalContainer {
      width: 75%;
      margin: auto;
      background-color: var(--white1);
      border: 2px solid var(--blue1);
      z-index: 1;
      .modalTitle {
        padding: 26px 0px 22px 0px;
        background-color: var(--blue1);
        font-weight: var(--font-bold);
        font-size: var(--large);
        color: var(--white1);
        text-align: center;
        .crossIcon {
          float: right;
          margin-right: 36px;
        }
      }
      .birthdayReport {
        .birthdayToday {
          display: flex;
          flex-direction: row;
          .birthdayTodayTitle {
            padding-top: 64px;
            width: 400px;
            @include titleStyle(var(--medium));
          }
          .userBirthdayToday {
            margin: 20px 0px 0px 90px;
            .userBirthdayTodayText {
              font-weight: var(--font-normal);
              font-size: var(--XSmall);
            }
          }
          .userBirthDate {
            display: flex;
            flex-direction: column;
            margin: 20px 0px 0px 100px;
            .birthDate {
              margin-bottom: 0px;
              .userBirthDateText {
                color: var(--blue3);
                font-weight: var(--font-bold);
                font-size: var(--XSmall);
                margin: 0px;
              }
            }
          }
          .divider {
            border: 1px solid #bfbfbf;
            margin: 0px;
          }
        }
        .birthdayUpcoming {
          .birthdayToday {
            display: flex;
            flex-direction: row;
            .birthdayTodayTitle {
              width: 400px;
              @include titleStyle(var(--medium));
            }
            .userBirthdayToday {
              width: 270px;
              margin: 20px 0px 0px 50px;
              .userBirthdayTodayText {
                font-weight: var(--font-normal);
                font-size: var(--XSmall);
                text-align: center;
              }
            }
            .userBirthDate {
              width: 200px;
              display: flex;
              flex-direction: column;
              margin: 20px 0px 0px 100px;
              .userBirthDateText {
                color: var(--blue3);
                font-weight: var(--font-bold);
                font-size: var(--XSmall);
                margin: 0px;
              }
            }
          }
        }
        .taskMeeting {
          display: flex;
          flex-direction: row;
          margin-bottom: 0px;
          .birthdayTodayTitle {
            padding-top: 64px;
            width: 400px;
            @include titleStyle(var(--medium));
          }
          .userBirthdayToday {
            margin: 0px 0px 0px 90px;
            .userBirthdayTodayText1 {
              font-weight: var(--font-normal);
              font-size: var(--XSmall);
              text-align: center;
            }
          }
          .userBirthDate {
            width: 230px;
            display: flex;
            flex-direction: column;
            margin-left: 35px;
            .birthDate {
              margin-bottom: 0px;
              .userBirthDateText {
                color: var(--blue3);
                font-weight: var(--font-bold);
                font-size: var(--XSmall);
                margin: 0px;
              }
            }
          }
        }
      }
      .modalFooter {
        height: 93px;
        background-color: var(--blue1);
        text-align: end;
        .goToReportButton {
          width: 234px;
          height: 68px;
          background: var(--white1);
          color: var(--blue1);
          box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
          border-radius: 2px;
          margin: 20px;
        }
      }
    }
  }
}
