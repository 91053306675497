@mixin buttonStyle(
  $margin,
  $color,
  $bg-color,
  $border,
  $font-size,
  $font-weight
) {
  color: $color;
  background: $bg-color;
  border: 1px solid $border;
  font-weight: $font-weight;
  font-size: $font-size;
  padding: 7px 20px;
}
@media only screen and (min-width: 768px) {
  .mainContainer {
    margin: 15px 0px 120px 0px;
    .benchmarkButton {
      display: flex;
      justify-content: flex-end;
      .addNewBenchmarkButton {
        width: 214px;
        height: 51px;
        background: var(--blue10);
        color: var(--blue1);
        font-weight: var(--font-semibold);
        font-size: var(--XSmall);
        padding: 10px;
        margin-right: 24px;
      }
      .updateBenchmarkButton {
        width: 214px;
        height: 51px;
        background: var(--blue10);
        color: var(--blue1);
        font-weight: var(--font-semibold);
        font-size: var(--XSmall);
        padding: 10px;
      }
    }
    .tableContent {
      margin-top: 15px;
      .table {
        width: 100%;
        th {
          font-weight: var(--font-medium);
          padding-left: 20px;
        }
        td {
          padding-left: 20px;
        }
        .tableHead {
          background: var(--blue11);
          height: 47px;
          color: var(--white1);
          font-size: var(--XXSmall);
          font-weight: var(----font-medium);
          text-align: left;
        }
        .tableBodyRow {
          background: var(--blue8);
          height: 66px;
          font-size: var(--small);
          font-weight: var(--font-normal);
          color: var(--black2);
          text-align: left;
          .buttonGroup {
            .addButton {
              margin-right: 21px;
              width: 128px;
              height: 50px;
              @include buttonStyle(
                0px,
                var(--black1),
                var(--blue8),
                var(--black1),
                var(--XSmall),
                var(--font-semibold)
              );
              &:hover {
                @include buttonStyle(
                  0px,
                  var(--white1),
                  var(--blue1),
                  var(--blue1),
                  var(--XSmall),
                  var(--font-semibold)
                );
              }
            }
            .removeButton {
              margin-right: 21px;
              width: 128px;
              height: 50px;
              @include buttonStyle(
                0px,
                var(--black1),
                var(--blue8),
                var(--black1),
                var(--XSmall),
                var(--font-semibold)
              );
              &:hover {
                @include buttonStyle(
                  0px,
                  var(--white1),
                  var(--blue1),
                  var(--blue1),
                  var(--XSmall),
                  var(--font-semibold)
                );
              }
            }
          }
          &:nth-child(even) {
            background: var(--blue9);
            .addButton {
              @include buttonStyle(
                0px,
                var(--black1),
                var(--blue9),
                var(--black1),
                var(--XSmall),
                var(--font-semibold)
              );
              &:hover {
                @include buttonStyle(
                  0px,
                  var(--white1),
                  var(--blue1),
                  var(--blue1),
                  var(--XSmall),
                  var(--font-semibold)
                );
              }
            }
          }
          &:nth-child(even) {
            background: var(--blue9);
            .removeButton {
              @include buttonStyle(
                0px,
                var(--black1),
                var(--blue9),
                var(--black1),
                var(--XSmall),
                var(--font-semibold)
              );
              &:hover {
                @include buttonStyle(
                  0px,
                  var(--white1),
                  var(--blue1),
                  var(--blue1),
                  var(--XSmall),
                  var(--font-semibold)
                );
              }
            }
          }
        }
      }
    }
  }
}
