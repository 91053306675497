@mixin container {
  background-color: var(--blue1);
  height: 63px;
  width: 411px;
  margin: auto;
}
@media only screen and(min-width:768px) {
    .profilePhotocontainer {
      background-color: var(--white1);
      width: 411px;
      height: 446px;
      margin: 10% auto 0px  auto;
      // vertical-align: middle;
      .crossContainer {
        @include container();
        .cross {
          padding: 26px 29px 26px 0px;
          text-align: right;
        }
      }

      .content {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        height: 320px;
        .profilePhoto {
          width: 164px;
          height: 161px;
        }
        .photoHeading {
          border: 1px solid var(--black1);
          color: var(--black1);
          background-color: var(--white1);
          font-weight: var(--font-medium);
          font-size: var(--XSmall);
          padding: 13px 29px 11px 29px;
        }
      }

      .crossSaveContainer {
        @include container();
        display: flex;
        justify-content: center;
        align-items: center;
        .saveButton {
          padding: 7px 77px;
          background-color: var(--blue10);
          color: var(--blue1);
          font-size: var(--medium);
          font-weight: var(--font-semibold);
        }
      }
    }
}
