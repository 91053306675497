@media only screen and (min-width: 768px) {
  .birthdayReportTable {
    box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
    border-radius: 1px;
    margin-top: 35px;
    .birthdayType {
      background: var(--blue1);
      padding: 11px 27px 11px 27px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .activeBirthdayText {
        color: var(--white1);
        font-weight: var(--font-semibold);
        font-size: var(--medium);
        cursor: pointer;
      }
      .birthdayText {
        color: var(--white1);
        font-weight: var(--font-light);
        font-size: var(--medium);
        cursor: pointer;
      }
    }
  }
}
