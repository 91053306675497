@media only screen and (min-width: 768px) {
  .waltCapitalContainer {
    width: 95%;
    margin: auto;
    padding: 13px;
    // position: relative;
    .waltCapitalHeding {
      width: 100%;
      height: 46px;
      padding: 12px 0px 20px 0px;
      .waltCapitalHedingInner {
        height: 21px;
        .waltCapitalHedingText {
          color: var(--blue1);
          font-weight: var(--font-semibold);
          font-size: var(--XSmall);
        }
      }
    }
  }
}
