@media only screen and (min-width: 768px) {
  .dailyTradeLogContainer {
    padding: 20px 22px 0px 27px;
    height: 100vh;
    .dateContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .dateText {
        font-size: var(--XSmall);
        font-weight: var(--font-semibold);
        margin-right: 23px;
      }
      // .dateBtn {
      //   background: var(--blue10);
      //   color: var(--blue1);
      //   padding: 15px 30px;
      //   margin-right: 20px;
      // }
      .datePickerContainer {
        display: flex;
        align-items: center;
        margin: 0px 20px 0px 10px;
        .datePicker {
          background: var(--white1);
          text-align: center;
          input {
            font-size: var(--XXSmall);
            font-weight: var(--font-medium);
            padding: 15px 20px;
            background: var(--white1);
            border: none;
            color: var(--grey1);
          }
          input:focus {
            outline: none;
          }
        }
        .iconContainer {
          padding: 10px 14px 10px 14px;
          margin-left: 10px;
        }
        .viewButton{
          margin-left: 30px;
          padding: 10px 30px;
          background: var(--blue10);
          color: var(--blue1);
          font-weight: var(--font-semibold);
          font-size: var(--XSmall);
        }
      }
      input[type="date"] {
        padding: 0px 0px 0px 40px;
        border-style: none;
        background: var(--blue2);
        box-shadow: 1px 1px 8px -1px rgba(0, 0, 0, 0.1);

        &::-webkit-calendar-picker-indicator {
          background-color: #f49554;
          font-size: 16px;
          padding: 18px;
          cursor: pointer;
          filter: invert(1);
        }
      }
      .editButton{
        // margin-left: 30px;
        padding: 10px 30px;
        background: var(--blue10);
        color: var(--blue1);
        font-size: var(--XSmall);
        font-weight: var(--font-semibold);
      }
      .saveButton{
        margin-left: 30px;
        padding: 10px 30px;
        background: var(--blue10);
        color: var(--blue1);
        font-size: var(--XSmall);
        font-weight: var(--font-semibold);
      }
    }
    .tableContent {
      margin-top: 20px;
      .table {
        width: 100%;
        th {
          font-weight: var(--font-medium);
          padding: 14px 8px;
        }
        td {
          padding: 0px 10px;
        }
        .tableHead {
          background: var(--blue11);
          color: var(--white1);
          font-size: var(--XXSmall);
          font-weight: var(----font-medium);
          text-align: center;
        }
        .tableBodyRow {
          background: var(--blue8);
          height: 66px;
          font-size: var(--XXSmall);
          font-weight: var(--font-medium);
          color: var(--black1);
          text-align: center;

          &:nth-child(even) {
            background: var(--blue9);
          }
          &:nth-child(even) {
            background: var(--blue9);
          }
        }
      }
    }
  }
}
