@media only screen and (min-width: 768px) {
  .allenGrayClientContainer {
    padding: 26px 38px 270px 38px;
    .dropdownButtonContainer {
      display: flex;
      margin-bottom: 25px;
      .dropdownContainerItems {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: var(--blue2);
        padding: 12px 20px;
        .dropdownContent {
          margin-left: 20px;
          font-size: var(--XSmall);
          font-weight: var(--font-normal);
          color: var(--black1);
        }
        .dropdownIcon {
          margin: 0px 20px;
          background: var(--blue2);
        }
      }
      .dropdownListContent {
        margin-top: 25px;
        border: 1px solid var(--grey1);
        border-top: none;
        width: 250px;
        height: 150px;
        overflow-y: scroll;
        background: var(--white1);
        color: var(--black1);
      }
      ::-webkit-scrollbar {
        width: 0px;
      }
      .viewButton,
      .printButton {
        padding: 13px 32px;
        background: var(--blue10);
        color: var(--blue1);
        font-size: var(--XSmall);
        font-weight: var(--font-semibold);
        margin-left: 30px;
      }
    }
    .filter{
      margin-bottom: 30px;
    }
    .searchFilter {
      width: 380px;
      .search {
        background: var(--grey17);
        box-shadow: none;
      }
      .input {
        background: var(--grey17);
      }
    }
    .toggleContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
      margin-top: 30px;
      .clientText {
        font-size: var(--XXSmall);
        font-weight: var(--font-medium);
        padding-left: 35px;
      }
      .toggleSwitchContent {
        display: flex;
        justify-content: space-between;
        .toggleText {
          display: flex;
          align-items: center;
        }
        .spanText {
          padding: 0px 16px 0px 26px;
          font-size: var(--XXSmall);
          font-weight: var(--font-semibold);
        }
      }
    }
    .tableContent {
      .table {
        width: 100%;
        th {
          font-weight: var(--font-medium);
          padding: 14px 0px 14px 28px;
          &:last-child {
            text-align: end;
            padding-right: 28px;
          }
        }
        td {
          padding: 0px 28px;
        }
        .tableHead {
          background: var(--blue11);
          color: var(--white1);
          font-size: var(--XSmall);
          font-weight: var(----font-medium);
          text-align: left;
        }
        .tableBodyRow {
          background: var(--blue8);
          height: 66px;
          font-size: var(--XXSmall);
          font-weight: var(--font-medium);
          color: var(--black1);
          text-align: left;

          &:nth-child(even) {
            background: var(--blue9);
          }
          &:nth-child(even) {
            background: var(--blue9);
          }
        }
      }
    }
  }
}
