@mixin textStyle($left, $right, $height, $color, $align) {
  //width: 177px;
  height: $height;
  padding: 10px $right 46px $left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-weight: var(--font-medium);
  font-size: var(--XSmall);
  color: var($color);
  text-align: $align;
  .textContainer {
    .text {
      font-weight: var(--font-medium);
      font-size: var(--XSmall);
      color: var($color);
      text-align: $align;
      .mutedText {
        color: transparent;
        text-shadow: 0 0 0 var(--grey1);
      }
    }
  }
}
@mixin middleHeaderText($align) {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-weight: var(--font-bold);
  font-size: var(--XSmall);
  color: var(--white1);
  text-align: $align;
}
@mixin dropdownContainerItems() {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .dropdownContent {
    margin-left: 20px;
    font-size: var(--XXSmall);
    font-weight: var(--font-medium);
    color: var(--grey1);
  }
  .dropdownIcon {
    margin-right: 15px;
  }
}

@mixin dropdownIcon() {
  //background-color: var(--blue7);
  border: none;
  line-height: 30px;
  // height: 30px;
  border-radius: 0px 8px 8px 0px;
  margin: 4px 0px 0px 0px;
}
@mixin dropdownItems() {
  background-color: var(--blue7);
  margin-top: unset;
  border-radius: 0px 0px 8px 8px;
  text-align: center;
}
@media only screen and (min-width: 768px) {
  .clientDetailsContainer {
    width: 95%;
    margin: auto;
    height: 100%;

    //background: var(--grey22);
    .clientDetailsHeaderLeft {
      padding-top: 20px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .datePickerContainer {
        display: flex;
        align-items: center;
        background: var(--white1);

        .datePicker {
          background: var(--white1);
          text-align: center;
          input {
            font-size: var(--XXSmall);
            font-weight: var(--font-medium);
            padding: 15px 20px;
            background: var(--white1);
            border: none;
            color: var(--grey1);
            width: 88%;
          }
          input:focus {
            outline: none;
          }
        }
        .datePick {
          color: var(--blue1);
        }
        .iconContainer {
          background: var(--white1);
          padding: 2px 8px 0px 0px;
        }
      }
      .clientDetailsDropdownContainer {
        display: flex;
        flex-direction: row;
        .dropdownMainContainer {
          .dropdownContainer {
            border: none;
            width: 217px;
            // height: 51px;
            padding: 8px;
            background: var(--white1);
            box-shadow: 1px 1px 8px -1px rgba(0, 0, 0, 0.1);
            display: flex;
            flex-direction: column;
            .dropdownContainerItems {
              @include dropdownContainerItems();
            }

            .dropdownListContent {
              margin-top: 10px;
              // position: relative;
              border: 1px solid var(--grey1);
              border-top: none;
              height: 150px;
              overflow-y: scroll;
            }
            ::-webkit-scrollbar {
              width: 0px;
            }
            .dropdownMain {
              position: relative;
            }
          }
          .dropdownIcon {
            @include dropdownIcon();
          }
          .dropdownItems {
            @include dropdownItems();
          }
        }
        // second dropdown
        .dropdownMainContainer1 {
          .dropdownContainer {
            border: none;
            width: 264px;
            padding: 8px;
            // height: 51px;
            background: var(--white1);
            box-shadow: 1px 1px 8px -1px rgba(0, 0, 0, 0.1);
            display: flex;
            flex-direction: column;
            .dropdownContainerItems {
              @include dropdownContainerItems();
            }

            .dropdownListContent {
              margin-top: 10px;
              // position: relative;
              width: 200px;
              border: 1px solid var(--grey1);
              border-top: none;
              height: 150px;
              overflow-y: scroll;
            }
            ::-webkit-scrollbar {
              width: 0px;
            }
            .dropdownMain {
              position: relative;
            }
          }
          .dropdownIcon {
            @include dropdownIcon();
          }
          .dropdownItems {
            @include dropdownItems();
          }
        }
      }

      .datePicker1 {
        width: 177px;
        height: 51px;
        background: var(--white1);
        .inputField {
          align-self: center;
          padding-left: 10px;
        }
        input[type] {
          border: none;
        }
      }
      .datePicker2 {
        width: 168px;
        height: 51px;
        background: var(--white1);
        .inputField {
          align-self: center;
          padding-left: 5px;
        }
        input[type] {
          border: none;
        }
      }
      .printButton {
        width: 131px;
        display: flex;
        justify-content: center;
        background: var(--blue10);
        color: var(--blue7);
        font-size: var(--XXSmall);
        font-weight: var(--font-semibold);
        padding-top: 14px;
        height: unset;
        .iconCustomClass {
          margin-right: 7px;
          display: inline-block;
        }
        .titleCustomClass {
          display: inline-block;
          margin-top: 2px;
        }
      }
    }

    .pageTitle {
      margin-top: 36px;
      background: var(--blue11);
      height: 65px;
      text-align: center;
      .title {
        padding: 17px 0px 18px 0px;
        color: var(--white1);
        font-weight: var(--font-medium);
        font-size: var(--medium);
      }
    }
    //card Container
    .clientDetailsCardContainer {
      //height: 327px;
      background: var(--white1);
      .cardTopContainer {
        height: 164px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 24px;
        .leftContainer {
          @include textStyle(20px, 0px, 90px, --black1, unset);
        }
        .rightContainer {
          @include textStyle(0px, 20px, 112px, --black1, right);
        }
      }
      .divider {
        border: 2px solid var(--grey16);
        background: var(--grey16);
      }

      .cardBottomContainer {
        margin-top: 29px;
        height: 161px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .leftContainer {
          @include textStyle(20px, 0px, 105px, --blue7, unset);
        }
        .rightContainer {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          .innerLeft {
            width: 33px;
            @include textStyle(0px, 20px, 112px, --blue7, unset);
          }
          .innerRight {
            width: 123px;
            @include textStyle(0px, 20px, 112px, --blue7, right);
          }
        }
      }
    }
    //middle header
    .middleHeaderContainer {
      height: 113px;
      background: var(--blue11);
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .middleHeaderLeft {
        padding: 21px 0px 22px 20px;
        @include middleHeaderText(unset);
      }
      .middleHeaderRight {
        padding: 21px 20px 22px 0px;
        @include middleHeaderText(right);
      }
    }
    .mainTableHeader {
      margin-bottom: 12px;
      margin-top: 53px;
      text-align: center;
      font-weight: var(--font-medium);
      font-size: var(--medium);
      color: var(--black1);
    }

    .table {
      margin-bottom: 60px; //not needed
      width: 100%;
      // th,
      // td {
      //   padding: 0px 5px;
      // }
      .tableHead {
        background: var(--blue11);
        height: 65px;
        color: var(--white1);
        font-size: var(--XXSmall);
        font-weight: var(--font-medium);
        text-align: left;
        input {
          height: 18px;
          width: 18px;
        }
      }
      .customClassForTh1 {
        padding: 0px 5px 0px 30px;
      }
      .customClassForTh2 {
        padding: 0px 5px;
      }
      .customClassForThLast {
        padding: 0px 30px 0px 5px;
      }
      .customClassForTd1 {
        padding: 0px 5px 0px 30px;
      }
      .customClassForTd2 {
        padding: 0px 5px;
        text-align: center;
      }
      .customClassForTdLast {
        padding: 0px 30px 0px 5px;
        text-align: center;
      }
      .customClassForTd {
        padding: 0px 5px;
        text-align: center;
        color: var(--red1);
      }
      .tableBodyRow {
        background: var(--blue8);
        height: 66px;
        font-size: var(--XXSmall);
        font-weight: var(--font-medium);
        color: var(--black2);
        &:nth-child(even) {
          background: var(--blue9);
        }
      }
    }
  }
}
