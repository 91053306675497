@media only screen and (min-width: 768px) {
  .homeContainer {
    margin-left: 30px;
    // margin-top: 30px;
    .title {
      font-size: var(--XSmall);
      font-weight: var(--font-semibold);
      padding: 30px 0px 0px 0px;
    }
    .homePageTitle {
      font-size: var(--XXSmall);
      font-weight: var(--font-semibold);
      color: var(--blue1);
      margin-bottom: 37px;
    }

    .tableContent {
      display: flex;
      justify-content: space-evenly;
      .tableContainer {
        .title {
          font-size: var(--XSmall);
          font-weight: var(--font-semibold);
          // padding: 30px 0px 0px 0px;
        }
        .watchlistContent {
          display: flex;
          margin-bottom: 36px;
          .symbolButton {
            background: var(--blue1);
            font-size: var(--XSmall);
            width: 110px;
            font-weight: var(--font-semibold);
            border: none;
            padding: 10px 20px;
            margin-right: 29px;
            border-radius: 2px;
          }
          .dropdownContainer {
            border: none;
            width: 252px;
            background: var(--blue2);
            box-shadow: 1px 1px 8px -1px rgba(0, 0, 0, 0.1);
            display: flex;
            flex-direction: column;
            position: relative;
            .dropdownContainerItems {
              display: flex;
              justify-content: space-between;
              align-items: center;
              .dropdownContent {
                margin-left: 20px;
                font-size: var(--XSmall);
                color: var(--grey9);
              }
              .dropdownIcon {
                margin-right: 15px;
                background-color: var(--white1);
              }
            }
            .dropdownListContent {
              margin-top: 10px;
              border-top: none;
              width: 188px;
              height: 150px;
              overflow-y: scroll;
            }
            ::-webkit-scrollbar {
              width: 0px;
            }
            .dropdownMain {
              position: absolute;
              top: 36px;
              box-shadow: 1px 1px 8px -1px rgba(0, 0, 0, 0.1);
            }
          }
          .symbolText {
            background-color: var(--grey3);
            padding: 5px 0px;
            text-align: center;
            width: 90px;
          }

          .dropdownIcon {
            background-color: var(--blue7);
            border: none;
            line-height: 30px;
            height: 30px;
            border-radius: 0px 8px 8px 0px;
            margin: 10px 0px 0px 0px;
          }
          .dropdownItems {
            background-color: var(--blue7);
            margin-top: unset;
            border-radius: 0px 0px 8px 8px;
            text-align: center;
          }
        }
        .table {
          border-collapse: collapse;
          background: var(--white1);
          margin-bottom: 86px;
          width: 100%;
          .tableHeaderRow,
          .tableRow {
            margin-bottom: 15px;
            border-bottom: 1px solid var(--grey14);
          }
          .serialNumber {
            .crossIconContainer {
              background: var(--blue6);
              color: var(--blue1);
              padding: 4px 5px 0px 5px;
              margin-top: 5px;
            }
          }
          .serialNumberHeading {
            background-color: var(--blue1);
            font-size: var(--small);
            font-weight: var(--font-semibold);
            padding-top: 13px;
            padding-bottom: 14px;
          }
          .tableHeading {
            background-color: var(--blue1);
            color: var(--white1);
            font-size: var(--XXSmall);
            font-weight: var(--font-semibold);
            padding: 9px 0px 15px 0px;
            white-space: nowrap;
          }
          .tableColumn {
            width: 85px;
            color: var(--green1);
            font-size: var(--XXSmall);
            font-weight: var(--font-bold);
            text-align: center;
            white-space: nowrap;
          }
          .tableColumn1 {
            width: 85px;
            color: var(--red1);
            font-size: var(--XXSmall);
            font-weight: var(--font-bold);
            text-align: center;
            white-space: nowrap;
          }

          .symbol {
            padding-left: 22px;
            padding-right: 10px;
            font-size: var(--XXSmall);
          }
        }
      }
      .lineChart {
        display: flex;
        flex-direction: column;
        align-self: flex-start;
        background: var(--white1);
        box-shadow: 0px 3px 6px rgba(62, 73, 84, 0.04);
        margin: 7% 30px 0px 30px;
        .chartContainer {
          margin-top: 38px;
          padding-left: 32px;

          .chartTitle {
            font-size: var(--MSmall);
            font-weight: var(--font-semibold);
          }
        }
        .rangeContainer {
          display: flex;
          align-items: center;
          margin: 42px 0px 40px 0px;
          justify-content: space-between;
          .dataContainer {
            padding-left: 23px;
          }
          .rangeOne {
            background-color: var(--blue2);
            color: var(--red1);
            font-size: var(--medium);
            font-weight: var(--font-semibold);
            padding: 18px 67px 20px 19px;
          }
          .rangeZero {
            background-color: var(--blue2);
            color: var(--grey1);
            font-size: var(--medium);
            font-weight: var(--font-semibold);
            padding: 18px 0px 20px 12px;
          }
          .rangeTwo {
            font-size: var(--medium);
            font-weight: var(--font-semibold);
            color: var(--grey1);
          }
          .rangeThree {
            background-color: var(--blue2);
            color: var(--blue1);
            font-size: var(--medium);
            font-weight: var(--font-semibold);
            padding: 18px 56px 20px 20px;
            margin-left: 33px;
          }
          .iconContainer {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            .refreshIconButton {
              padding: 0px 33px 0px 42px;
            }
          }
        }
      }

      .dateRangeContainer {
        display: flex;
        margin: 2px 0px;
        background: var(--blue2);
        filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.1));
        margin-left: 30px;
        margin-right: 30px;
        .dateRangeItem {
          padding: 20px 0px 13px 22px;
          color: var(--grey10);
          font-size: var(--XXSmall);
        }
        .dateRangeIcon {
          padding: 17px 8px;
        }
      }
    }
  }
}
