@media only screen and (min-width: 768px) {
  .accountContainer {
    border: 1px solid #b9b7bc;
    margin: 0px 22px 25px 22px;
    width: 245px;
    height: 90px;
    .investmentAccount {
      padding: 10px;
      .amountText {
        font-size: var(--small);
        font-weight: var(--font-semibold);
        color: var(--black5);
      }
      .accountName {
        font-size: var(--XXXSmall);
        font-weight: var(--font-medium);
        color: var(--black5);
      }
    }
    & :hover {
      background: var(--blue7);
      .accountId {
        color: var(--white1);
      }
      .amountText,
      .accountName {
        color: var(--white1);
      }
    }
  }
}
