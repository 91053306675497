@media only screen and (min-width : 768px) {
    .notificationHide{
        font-size: var(--XXSmall); 
        position: fixed;
        top: 12px;
        right: 12px;
        // transition: visibility .6s ease-in-out;
        transform: 0.6s ease-in-out;
        animation: toast-in-left .7s;
        visibility: hidden;                      
        // opacity: 0;
        .notification {
            // transition: .3s ease;
            position: relative;
            border-radius: 3px 3px 3px 3px;
            box-shadow: 0 0 10px #999;
            opacity: .9;
            height: 50px;
            width: 365px;
            .notificationHeader {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding:10px;
                .title{
                    display: flex;
                    align-items: center;
                }
                .image {
                    margin-right: 5px;
                    width: 30px;
                    height: 30px;
                }
                .notificationTitle {
                    font-weight: var(--font-bold);
                    font-size: var(--XSmall);
                    // text-align: left;
                    margin-top: 0;
                    margin-bottom: 6px;
                    width: 300px;
                    height: 18px;
                }
            }
            &:hover {
                box-shadow: 0 0 12px var(--white1);
                opacity: 1;
                cursor: pointer
            }
        }
    }
    .notificationShow {
        visibility: visible;
        opacity: 1;
        font-size: var(--XXSmall); 
        position: fixed;
        top: 12px;
        right: 12px;
        // transition: visibility .6s ease-in-out;
        transform: 0.6s ease-in-out;
        animation: toast-in-right .7s;
        .notification {
            // transition: .3s ease;
            position: relative;
            border-radius: 3px 3px 3px 3px;
            box-shadow: 0 0 10px #999;
            opacity: .9;
            height: 50px;
            width: 365px;
            .notificationHeader {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding:10px;
                .title{
                    display: flex;
                    align-items: center;
                }
                .image {
                    margin-right: 5px;
                    width: 30px;
                    height: 30px;
                }
                .notificationTitle {
                    font-weight: var(--font-bold);
                    font-size: var(--XSmall);
                    // text-align: left;
                    margin-top: 0;
                    margin-bottom: 6px;
                    width: 300px;
                    height: 18px;
                }
            }
            &:hover {
                box-shadow: 0 0 12px var(--white1);
                opacity: 1;
                cursor: pointer
            }
        }
        
    }
    @keyframes toast-in-right {
        from {
          transform: translateX(100%);
          visibility : hidden
        }
        to {
          transform: translateX(0);
          visibility: visible;
        }
    }
    @keyframes toast-in-left {
        from {
            transform: translateX(0%);
            visibility: visible;
        }
        to {
            transform: translateX(100%);
            visibility: hidden;
        }
    }
}