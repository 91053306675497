@mixin dropdown($width, $itemWidth, $paddingLeft, $paddingRight) {
  width: $width;
  box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
  background: var(--white1);
  // margin-left: 20px;
  padding: 15px 0px;
  position: relative;

  .dropdownMain {
    position: absolute;
  }

  .dropdownContainerItems {
    display: flex;
    justify-content: space-between;
    width: 90%;
    align-items: center;
    position: relative;

    .dropdownContent {
      font-size: var(--XXSmall);
      color: var(--grey1);
      margin-left: 20px;
    }
  }

  .dropdownListContent {
    margin-top: 10px;
    // position: relative;
    width: $itemWidth;
    // padding: 15px 14px;
    padding: 15px $paddingLeft 15px $paddingRight;
    background: var(--white1);
    height: 150px;
    overflow-y: scroll;
  }

  ::-webkit-scrollbar {
    width: 0px;
  }
}

@media only screen and (min-width: 768px) {
  .aumContainer {
    padding: 32px 0px 32px 0px;
    display: flex;
    flex-direction: row;
    // justify-content: space-around;

    margin-left: 20px;
    margin-right: 20px;

    //new
    flex-wrap: wrap;

    .dashboardHeaderContainer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;

      .dateInput {
        display: flex;
        flex-direction: row;

        input[type="date"] {
          padding-left: 27px;
          border: 1px solid var(--white1);
          background: var(--white1);

          &::-webkit-calendar-picker-indicator {
            background-color: white;
            font-size: 16px;
            padding: 15px;
            cursor: pointer;
          }
        }
      }

      .datePickerContainer {
        display: flex;
        align-items: center;
        margin: 0px 0px 0px 4px;

        .datePicker {
          background: var(--white1);
          text-align: center;

          input {
            font-size: var(--XXSmall);
            font-weight: var(--font-medium);
            padding: 15px 0px 15px 20px;
            background: var(--white1);
            border: none;
            color: var(--grey1);
          }

          input:focus {
            outline: none;
          }
        }

        .datePick {
          color: var(--blue1);
        }

        .iconContainer {
          background: var(--white1);
          padding: 10px 15px;
        }
      }

      .viewBtn {
        max-width: 18%;
        padding: 15px 22px;
        font-size: var(--XXSmall);
        font-weight: var(--font-semibold);
        background: var(--blue10);
        color: var(--blue1);
      }

      .searchBar {
        width: 30%;

        .search {
          background: var(--grey17);
          box-shadow: none;
          width: 90%;
        }

        .input {
          width: 75%;
          background: var(--grey17);
          margin: 0px;
        }
      }

      .dropdownContainer {
        margin-left: 10px;
        @include dropdown(21%, 75%, 13%, 10%);
      }

      .dropdownContainer1 {
        @include dropdown(17%, 50%, 0px, 25px);
      }
    }

    .cardContainer {
      max-width: 29%;
      flex: 1;

      .amountContainer {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 30px;
        justify-content: space-around;
      }
      .amountContainer1 {
        margin-top: 30px;
      }
    }

    .dataTable {
      // max-width: 46%;
      margin-left: 20px;
      margin-top: 5px;
      flex: 1;
      min-width: 460px;

      .balanceContainer {
        background: var(--white1);
        box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
        margin-top: 30px;
        padding: 15px 30px 15px 30px;

        .balanceContent {
          padding: 15px 0px 15px 0px;

          .border1CustomClass {
            border: 1px solid var(--blue18);
          }

          .border2CustomClass {
            border: 1px solid var(--orange2);
          }
        }
      }
    }

    .dashboardContainer {
      height: 100vh;
      display: grid;
      grid-template-columns: 1fr 4fr;

      .dashboardContent {
        height: 100%;

        .children {
          background: var(--blue2);
        }
      }
    }
  }
}
