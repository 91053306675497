@media only screen and (min-width : 768px) {
    .editBtn{
        font-size: var(--XXSmall);
        font-weight: var(--font-semibold);
        background: var(--blue10);
        color: var(--blue1);
        height: 42px;
        padding: 7px 25px;
        width:100%
    }
}