@media only screen and (min-width : 768px) {
    .popup {
        position: absolute;
        width: 100%;
        // height: 100%;
        min-height: 100vh;
        top: 0;
        left: 0;
        // display: flex;
        // align-items: center;
        // justify-content: center;
        background: var(--black6);
        // opacity: 80%;
        z-index: 10; 
        overflow-y: auto; 
    }
}