@media only screen and (min-width: 768px) {
  .button {
    background-color: var(--blue1);
    font-size: var(--medium);
    color: var(--white1);
    border: none;
    cursor: pointer;
    font-weight: var(--font-bold);
  }
}
