@media only screen and (min-width: 768px) {
  .dashboardContainer {
    padding: 32px 0px 32px 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-left: 20px;

    //new
    flex-wrap: wrap;
    .dashboardHeaderContainer {
      display: flex;
      flex-direction: row;
      width: 100%;

      .dataViewContainer {
        display: flex;
        flex-direction: row;
        width: 42%;

        .dateInput {
          display: flex;
          flex-direction: row;
          input[type="date"] {
            padding-left: 27px;
            border: 1px solid var(--white1);
            background: var(--white1);
            &::-webkit-calendar-picker-indicator {
              background-color: white;
              font-size: 16px;
              padding: 15px;
              cursor: pointer;
            }
          }
        }
        .datePickerContainer {
          display: flex;
          align-items: center;
          margin: 0px 20px 0px 10px;
          .datePicker {
            background: var(--white1);
            text-align: center;
            input {
              font-size: var(--XXSmall);
              font-weight: var(--font-medium);
              padding: 15px 0px 15px 20px;
              background: var(--white1);
              border: none;
              color: var(--grey1);
            }
            input:focus {
              outline: none;
            }
          }
          .datePick {
            color: var(--blue1);
          }
          .iconContainer {
            background: var(--white1);
            padding: 10px;
          }
        }
        .viewBtn {
          padding: 15px 34px;
          font-size: var(--XXSmall);
          font-weight: var(--font-semibold);
          background: var(--blue10);
          color: var(--blue1);
        }
      }
      .dropdowns {
        display: flex;
        flex-direction: row;
        width: 60%;

        .dailyTradeLogButton {
          width: 160px;
          height: 50px;
          background: #0b6aab;
          padding: 10px;
          font-weight: 600;
          font-size: 16px;
        }
        .dropdownContainer {
          width: 217px;
          box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
          background: var(--white1);
          margin-left: 20px;
          padding: 15px 0px;
          position: relative;
          .dropdownMain {
            position: absolute;
          }
          .dropdownContainerItems {
            display: flex;
            justify-content: space-between;
            width: 90%;
            align-items: center;
            position: relative;
            .dropdownContent {
              font-size: var(--XXSmall);
              color: var(--grey1);
              margin-left: 20px;
            }
          }
          .dropdownListContent {
            margin-top: 10px;
            // position: relative;
            width: 188px;
            padding: 15px 14px;
            background: var(--white1);
            height: 150px;
            overflow-y: scroll;
          }
          ::-webkit-scrollbar {
            width: 0px;
          }
        }
      }
    }
    .cardContainer {
      max-width: 46%;
      flex: 1;
      .amountContainer {
        display: flex;
        flex-direction: row;
        width: 100%;
        margin-top: 25px;
        justify-content: space-around;
      }
      .monthlyFeesContainer {
        display: flex;
        flex-direction: row;
        margin-top: 30px;
        justify-content: space-around;
      }
    }
    .dataTable {
      // max-width: 46%;
      margin-left: 20px;
      flex: 1;
      min-width: 460px;

      .balanceContainer {
        background: var(--white1);
        box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
        margin-top: 30px;
        padding: 15px 30px 15px 30px;
        .balanceContent {
          padding: 15px 0px 15px 0px;
          .border1CustomClass {
            border: 1px solid var(--blue18);
          }
          .border2CustomClass {
            border: 1px solid var(--orange2);
          }
        }
      }
    }
    .dashboardContainer {
      height: 100vh;
      display: grid;
      grid-template-columns: 1fr 4fr;
      .dashboardContent {
        height: 100%;
        .children {
          background: var(--blue2);
        }
      }
    }
  }
}
