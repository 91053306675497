@media only screen and (min-width: 768px) {
  @mixin buttonStyle($margin, $color, $bg-color, $border) {
    color: $color;
    background: $bg-color;
    border: 1px solid $border;
    font-weight: var(--font-semibold);
    font-size: var(--XSmall);
    padding: 7px 8px;
  }
  .initialColumn {
    text-align: center;
  }
  .name {
    font-size: var(--XXSmall);
    font-weight: var(--font-semibold);
  }
  .age {
    font-size: var(--XXSmall);
    font-weight: var(--font-medium);
  }
  .birthdayDate {
    font-size: var(--XXSmall);
    font-weight: var(--font-semibold);
    color: var(--blue1);
  }
  .nameColumn {
    width: 40%;
  }
  .idColumn {
    width: 20%;
  }
  .amountColumn {
    width: 40%;
    text-align: end;
  }
  .nameClass {
    font-size: var(--XSmall);
    font-weight: var(--font-bold);
  }
  .aumTotal{
    padding-left: 25px;
  }
}
