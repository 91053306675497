@mixin buttonStyle($margin, $color, $bg-color, $border) {
  color: $color;
  background: $bg-color;
  border: 1px solid $border;
  font-weight: var(--font-semibold);
  font-size: var(--XSmall);
  padding: 9px 26px 9px 26px;
}

@media only screen and (min-width: 768px) {
  .offshoreClientListMainContainer {
    padding: 30px;
    .blocksContainer{
      display: flex;
      justify-content: space-between;
      .activeClass{
        background: var(--blue7);
        color: var(--white1);
        border: 1px solid var(--blue7);
        font-weight: var(--font-bold);
      }
    }
    .title {
      color: var(--black1);
      font-size: var(--medium);
      font-weight: var(--font-medium);
      margin-top: 14px;
    }
    .allClients {
      margin-top: 15px;
    }
    .navlink {
      margin-bottom: 15px;
      font-size: var(--XXSmall);
      color: var(--grey1);
      font-weight: var(--font-normal);
      padding: 12px;
      background: var(--grey15);
    }
    // .navlinkTextBlue {
    // }
    .search {
      background: var(--grey17);
      box-shadow: none;
      width: 33%;
    }
    .input {
      width: 75%;
      background: var(--grey17);
      margin: 0px;
    }
    .tableContent {
      margin-top: 14px;
      .table {
        width: 100%;

        // th,
        // td {
        //   padding: 0px 33px 0px 33px;
        // }

        .tableHead {
          background: var(--blue11);
          height: 50px;
          color: var(--white1);
          font-size: var(--XSmall);
          font-weight: var(--font-medium);
          text-align: left;
          input {
            height: 18px;
            width: 18px;
          }
          .customClassForTh{
            padding: 0px 33px 0px 33px;
          }
        }
        .tableBodyRow {
          background: var(--blue8);
          height: 66px;
          font-size: var(--XXSmall);
          font-weight: var(--font-medium);
          color: var(--black1);

          // .gotoButton {
          //   @include buttonStyle(
          //     0px,
          //     var(--black1),
          //     var(--blue8),
          //     var(--black1)
          //   );
          //   &:hover {
          //     @include buttonStyle(
          //       0px,
          //       var(--white1),
          //       var(--blue1),
          //       var(--blue1)
          //     );
          //   }
          // }
          &:nth-child(even) {
            background: var(--blue9);

            // .gotoButton {
            //   @include buttonStyle(
            //     0px,
            //     var(--black1),
            //     var(--blue9),
            //     var(--black1)
            //   );
            //   &:hover {
            //     @include buttonStyle(
            //       0px,
            //       var(--white1),
            //       var(--blue1),
            //       var(--blue1)
            //     );
            //   }
            // }
          }
        }
      
        .customClassForTd{
          padding: 0px 33px 0px 33px;
        }
        .customClassForTd1{
          padding: 0px 0px;
        }
        .customClassForTd2{
          padding: 0px 32px;

        }
        .tableFooter{
          background: var(--blue10);
          height: 66px;
          font-size: var(--small);
          font-weight: var(--font-medium);
          color: var(--black1);
        }
      }
      .offshoreFooter {
        background: var(--blue7);
        display: flex;
        justify-content: center;
        .footer {
          display: flex;
          justify-content: space-between;
          width: 42%;
          font-weight: var(--font-semibold);
          font-size: var(--small);
          color: var(--white1);
        }

        .number {
          margin-right: 15%;
        }
      }
    }

    .paginationContainer {
      display: flex;
      justify-content: space-between;
      padding: 30px 0px 0px 0px;
    }
  }
}
