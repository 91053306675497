@media only screen and (min-width: 768px) {
    
  .textFieldWithIcon{
    display: flex;
    flex-direction: row;
  
  .inputType {
    //padding: 5px 0px;
    color: var(--black2);
    background: var(--grey18);
    border: none;
  }
  .iconContainer {
    background-color: var(--blue1);
    height: 29px;
    width:49px;
  
  .icon{
    padding: 6px 14px 4px 14px;
  }
}
}

}
