@media only screen and (min-width: 768px) {
  .emailShow {
    margin-left: 20px;
    .emailText {
      font-size: var(--XXXXXSmall);
      font-weight: var(--font-medium);
      color: var(--blue7);
      position: absolute;
      background: var(--white1);
      padding: 10px;
      box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.22);
    }
    & ::after {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      margin-right: auto;
      margin-left: auto;
      top: 100%;
      width: 0;
      height: 0;
      border-top: 10px solid var(--white1);
      border-right: 10px solid transparent;
      border-bottom: 0 solid transparent;
      border-left: 10px solid transparent;
    }
  }
}
