@media only screen and (min-width: 768px) {
  .runfeesPPMStyle {
    // height: 100vh;
    padding-bottom: 50px;
    .runfeesPPMContainer {
      padding: 30px 30px 0px 30px;
      // min-height: 100vh;
      // background: var(--grey22);
      .titleButtonContainer {
        display: flex;
        justify-content: space-between;
        margin-bottom: 30px;
        .titleContainer {
          .titleText {
            font-size: var(--small);
            font-weight: var(--font-medium);
            margin: 0px;
            padding: 0px;
          }
          .endDate {
            font-size: var(--XSmall);
            font-weight: var(--font-medium);
            color: var(--grey1);
            margin: 0px;
            padding: 0px;
          }
        }
        .printContainer {
          color: var(--blue7);
          background: var(--blue10);
          padding: 15px 20px;
          justify-content: end;
          // text-align: end;
          display: flex;
          align-items: center;
          // margin-left: 89%;
          .buttonText {
            color: var(--blue7);
            font-size: var(--XXSmall);
            font-weight: var(--font-semibold);
            background: var(--blue10);
            // padding: 16px 10px;
          }
        }
      }
      .abcFilter {
        margin-bottom: 30px;
      }
      .buttonDropdownContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .datePickerContainer {
          display: flex;
          align-items: center;
          width: 20%;
          .datePicker {
            background: var(--white1);
            text-align: center;
            input {
              font-size: var(--XXSmall);
              font-weight: var(--font-medium);
              padding: 15px 0px 15px 20px;
              background: var(--white1);
              border: none;
              color: var(--grey1);
            }
            input:focus {
              outline: none;
            }
          }
          .datePick {
            color: var(--blue1);
          }
          .iconContainer {
            background: var(--white1);
            padding: 10px;
          }
        }
        .viewBtn {
          color: var(--blue7);
          font-size: var(--XSmall);
          font-weight: var(--font-semibold);
          background: var(--blue10);
          padding: 15px 30px;
        }
        .searchFilter {
          width: 262px;
          .search {
            background: var(--grey17);
            box-shadow: none;
          }
          .input {
            background: var(--grey17);
          }
        }
        .dropdownButtonContainer {
          display: flex;
          position: relative;
          .dropdownContainerItems {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: var(--blue2);
            padding: 12px 20px;
            .dropdownContent {
              margin-left: 20px;
              font-size: var(--XXSmall);
              font-weight: var(--font-normal);
              color: var(--grey1);
            }
            .dropdownIcon {
              margin: 0px 20px;
              background: var(--blue2);
            }
          }
          .dropdownListContent {
            margin-top: 25px;
            border: 1px solid var(--grey1);
            border-top: none;
            width: 150px;
            height: 150px;
            overflow-y: scroll;
            background: var(--white1);
            color: var(--black1);
          }
          ::-webkit-scrollbar {
            width: 0px;
          }
        }
      }
      .tableContainer {
        margin-top: 30px;
        overflow-x: auto;
        // &::-webkit-scrollbar {
        //   visibility: hidden;
        // }
        .table {
          width: 120%;
          th,
          td {
            padding: 0px 15px;
          }
          .tableHead {
            background: var(--blue11);
            height: 66px;
            color: var(--white1);
            font-size: var(--XXSmall);
            font-weight: var(--font-medium);
            text-align: left;
          }
          .tableBodyRow {
            background: var(--blue8);
            height: 66px;
            font-size: var(--XXSmall);
            font-weight: var(--font-medium);
            color: var(--black2);
            cursor: pointer;
            // padding: 0px 15px;
            &:nth-child(even) {
              background: var(--blue9);
            }
            &:last-child {
              background: var(--blue10);
              font-weight: var(--font-semibold);
            }
          }
          // .customClassForTd1 {
          //   position: absolute;
          // }
          .customClassForTd {
            background: var(--blue10);
            // height: 35px;
            font-size: var(--XXSmall);
            font-weight: var(--font-semibold);
            color: var(--black2);
            padding: 10px 15px;
          }
          .customClassForTd2 {
            color: var(--red1);
            background: var(--blue10);
            // height: 35px;
            font-size: var(--XXSmall);
            font-weight: var(--font-semibold);
            padding: 10px 15px;
          }
        }
      }
      .tableContainerTwo {
        margin-top: 30px;
        overflow-x: auto;
        .table {
          width: 188%;
          th,
          td {
            padding: 0px 15px;
          }
          .tableHead {
            background: var(--blue11);
            height: 66px;
            color: var(--white1);
            font-size: var(--XXSmall);
            font-weight: var(--font-medium);
            text-align: left;
          }
          .tableBodyRow {
            background: var(--blue8);
            height: 66px;
            font-size: var(--XXSmall);
            font-weight: var(--font-medium);
            color: var(--black2);
            cursor: pointer;
            // padding: 0px 15px;
            &:nth-child(even) {
              background: var(--blue9);
            }
            &:last-child {
              background: var(--blue10);
              font-weight: var(--font-semibold);
            }
          }
          .customClassForTd {
            background: var(--blue10);
            // height: 35px;
            font-size: var(--XXSmall);
            font-weight: var(--font-semibold);
            color: var(--black2);
            padding: 10px 15px;
          }
          .customClassForTd2 {
            color: var(--red1);
            background: var(--blue10);
            // height: 35px;
            font-size: var(--XXSmall);
            font-weight: var(--font-semibold);
            padding: 10px 15px;
          }
        }
      }
    }
  }
}
