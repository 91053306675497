@mixin tableText() {
  font-size: var(--XSmall);
  font-weight: var(--font-semibold);
  background: var(--blue1);
  text-align: center;
  color: var(--white1);
  padding: 19px 0px;
}
@media only screen and (min-width: 768px) {
  .clientTransactionContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 24px 0px 73px 0px;
    justify-content: space-between;
    .capitalGainContainer {
      width: 48%;
      .chart {
        padding: 16px 24px 12px 14px;
        background: var(--white1);
        .capitalGainTitle {
          font-size: var(--XSmall);
          font-weight: var(--font-semibold);
        }
      }
      .table {
        width: 100%;
        border-collapse: collapse;
        margin-top: 13px;
        .yearHeading {
          font-size: 10px;
          color: var(--white1);
          background: var(--blue7);
          padding: 10px 0px;
        }
        .yearRow {
          padding: 12px 0px;
          font-size: 10px;
          background: var(--blue13);
          border-right: 1px solid var(--black1);
        }
      }
      .latestCommentoryText {
        font-size: var(--XXXSmall);
        margin: 18px 0px 16px 0px;
      }
      .tableText {
        @include tableText;
      
      }
      .fundSuitsStatsTable {
        border-collapse: collapse;
        width: 100%;
        .tableHeading {
          background: var(--blue8);
        }
        .thead {
          background: var(--blue8);
        }
        .tbody {
          background: var(--blue9);
        }
      }
    }
    .returnsContainer {
      width: 48%;
      .returnsTable {
        border-collapse: collapse;
        width: 100%;
        .tableText {
          @include tableText;
        }
        .tableRow {
          &:nth-child(even) {
            background: var(--blue8);
          }
          &:nth-child(odd) {
            background: var(--blue9);
          }
        }
      }
      .fundStatsTable {
        margin-top: 37px;
        border-collapse: collapse;
        width: 100%;
        .fundStatsText {
          @include tableText;
        }
        .tableRow {
          &:nth-child(even) {
            background: var(--blue8);
          }
          &:nth-child(odd) {
            background: var(--blue9);
          }
        }
        .tableData {
          padding: 14px 30px;
        }
      }
    }
  }
}
